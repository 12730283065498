import { makeTemplate, TranslationKey } from "./languages";

type TranslationType = {
  pageHeader?: string;
  selectCountryHeader?: string;
};
const translations: Record<TranslationKey, TranslationType> = {
  "en-GB": {
    pageHeader: "Tax related information",
    selectCountryHeader: "Which country do you want information about?",
  },
  "da-DK": {
    pageHeader: "General information om skat",
    selectCountryHeader: "Hvilket land søger du information om?",
  },
  "fi-FI": {
    pageHeader: "Tietoa verotuksesta",
    selectCountryHeader: "Mistä maasta haluat tietoa?",
  },
  "is-IS": {
    pageHeader: "Upplýsingar um skatta",
    selectCountryHeader: "Um hvaða land þarftu upplýsingar?",
  },
  "no-NO": {
    pageHeader: "Generell informasjon om skatt",
    selectCountryHeader: "Hvilket land vil du ha informasjon om?",
  },
  "sv-SE": {
    pageHeader: "Generell information om skatt",
    selectCountryHeader: "Vilket land söker du information om?",
  },
};

export const getTranslation = (
  languageCode: TranslationKey,
  prop: keyof TranslationType
) => {
  const langObj = translations[languageCode];
  return langObj?.[prop] ?? translations["en-GB"]?.[prop] ?? "_";
};
