import React, { FC, ReactNode, useEffect, useState } from "react";
import { darken } from "polished";
import styled from "styled-components";
import { CountryKey } from "../../../translations/countries";
import { getTranslation } from "../../../translations/taxation";
import {
  mediaBreakpoints,
  colorThemes,
  rhythm,
} from "../../../utils/typography";
import PageTopics, { PageTopic } from "../../molecules/pageTopics";
import SelectCountry from "../selectCountry";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import { TranslationKey } from "../../../translations/languages";
import { useLocation } from "@reach/router";
import queryString from "query-string";

const { md } = mediaBreakpoints;
const {
  separator: { primary: separatorPrimary },
  selectCountry: { primary: selectCountryPrimary },
} = colorThemes;

type PageContent = string | JSX.Element | JSX.Element[];

type TaxTopicId = string;
export type CountryContent = Record<CountryKey, PageContent>;
export type TaxCountryContent = Record<TaxTopicId, CountryContent>;
export type TaxContentProps = {
  contents: TaxCountryContent;
  topics: Array<PageTopic>;
  onCountrySelected: (country: CountryKey) => void;
  selectedCountry?: CountryKey;
  onTopicSelected: (topic: string) => void;
  selectedTopic?: string;
};

const VerticalContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`;

type TopicContentContainerProps = {
  isVisible?: boolean;
};

const TopicContentContainer = styled.div<TopicContentContainerProps>`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  margin-top: ${rhythm(1)};
  @media only screen and (min-width: ${md}) {
    margin-top: ${rhythm(3)};
    flex-flow: row nowrap;
  }
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
`;

const SelectCountryContainer = styled.div`
  background-color: ${selectCountryPrimary};
  border: 1px solid ${darken(0.1, selectCountryPrimary)};
  padding: ${rhythm(2)};
  width: 100%;
  height: 100%;
`;

type TopicsContainerProps = {
  hasContent?: boolean;
};

const TopicsContainer = styled.div<TopicsContainerProps>`
  height: 100%;
  width: 100%;
  flex-basis: 100%;
  @media only screen and (min-width: ${md}) {
    flex-basis: ${({ hasContent }) => (hasContent ? "30%" : "50%")};
    border-bottom: 0px;
    border-left: ${({ hasContent }) =>
      hasContent ? `2px solid ${separatorPrimary}` : "0px"};
    padding-left: ${({ hasContent }) => (hasContent ? rhythm(1) : "0px")};
  }
`;

const ContentContainer = styled.section`
  height: 100%;
  width: 100%;
  flex-basis: 100%;
  margin-top: ${rhythm(1)};
  @media only screen and (min-width: ${md}) {
    flex-basis: 70%;
    margin-top: 0px;
    margin-right: ${rhythm(2)};
  }
`;

const TaxContent: FC<TaxContentProps> = ({
  topics,
  contents,
  onCountrySelected,
  selectedCountry,
  onTopicSelected,
  selectedTopic,
}) => {

  const [content, setContent] = useState<PageContent | undefined>(undefined);

  const { languageCode } = useLanguageState();

  useEffect(() => {
    let nextContent: PageContent | undefined = undefined;
    if (selectedTopic && selectedCountry) {
      const topicContent = contents[selectedTopic];
      if (topicContent) {
        nextContent = topicContent[selectedCountry];
      }
    }
    setContent(nextContent);
  }, [selectedCountry, selectedTopic, languageCode]);

  const selectCountryHeader = getTranslation(
    languageCode as TranslationKey,
    "selectCountryHeader"
  );

  return (
    <VerticalContainer>
      <SelectCountryContainer>
        <h4 aria-hidden="true">{selectCountryHeader}</h4>
        <SelectCountry
          onCountrySelected={onCountrySelected}
          selectedCountry={selectedCountry as string}
          ariaLabel={selectCountryHeader}
        />
      </SelectCountryContainer>
      <TopicContentContainer isVisible={!!selectedCountry} aria-live="polite">
        {content && <ContentContainer>{content}</ContentContainer>}
        <TopicsContainer hasContent={!!content} aria-live="polite">
          <PageTopics
            selectedTopic={selectedTopic}
            topics={topics}
            onTopicSelected={onTopicSelected}
          />
        </TopicsContainer>
      </TopicContentContainer>
    </VerticalContainer>
  );
};

export default TaxContent;
