import React, { FC } from "react";
import styled from "styled-components";
import { colorThemes, rhythm, scale } from "../../../utils/typography";
import ActionButton from "../actionbutton";
import { compareByPresentationOrder } from "../../../utils/misc";
import { ActionButtonProps } from "../actionbutton";
import { darken } from "polished";
import { bodyTextStyles } from "../../atoms/pageContent";

const {
  separator: { primary: separatorPrimary },
  button: { secondary: buttonSecondary, border: buttonBorder },
} = colorThemes;

export type PageTopic = {
  id: string;
  header: string;
  presentationOrder: number;
};

export type PageTopicsProps = {
  topics: Array<PageTopic>;
  onTopicSelected: (id: string) => void;
  selectedTopic?: string;
};

const TopicButton = styled(ActionButton)<ActionButtonProps>`
  width: 100%;
  border-width: 0px;
  border-bottom: 1px solid ${darken(0.1, separatorPrimary)};
  border-radius: 0px;
  min-height: ${rhythm(3.5)};

  border-color: ${({ isSelected }) =>
    isSelected ? darken(0.2, buttonBorder) : null};

  &&:hover {
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    box-shadow: unset;
  }

  &:focus {
    border-top: 0px;
    border-right: 0px;
    border-color: ${darken(0.2, buttonBorder)};
    box-shadow: unset;
    & .content_right {
      visibility: ${({ isSelected }) => (isSelected ? "visible" : "hidden")};
    }
  }

  &:not([disabled]),
  &:not([aria-disabled="true"]) {
    &:active,
    &[data-active="true"] {
      border-top: 0px;
      border-right: 0px;
      border-color: ${buttonSecondary};
      box-shadow: unset;
    }
  }

  & .content_right {
    visibility: ${({ isSelected }) => (isSelected ? "visible" : "hidden")};
  }
`;

const PageTopicsContainer = styled.nav`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`;

const { fontSize: topicFontSize, lineHeight: topicLineHeight } = scale(0 / 5);
const TopicText = styled.span`
  ${bodyTextStyles}
  line-height: ${topicLineHeight};
  font-size: ${topicFontSize};
  text-align: left;
`;

const PageTopics: FC<PageTopicsProps> = ({
  topics,
  onTopicSelected,
  selectedTopic,
}) => {
  return (
    <PageTopicsContainer>
      {topics.sort(compareByPresentationOrder).map(({ id, header }) => (
        <TopicButton
          key={id}
          aria-label={header}
          action="select"
          contentLeft={<TopicText>{header}</TopicText>}
          onClick={() => onTopicSelected(id)}
          isSelected={selectedTopic === id}
        />
      ))}
    </PageTopicsContainer>
  );
};

export default PageTopics;
