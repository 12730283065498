import React, { FC, useEffect, useState, useCallback } from "react";
import queryString from "query-string";
import { getTranslation } from "../translations/taxation";
import { TranslationKey } from "../translations/languages";
import { isStatusAllowed } from "../utils/misc";
import { PageProps } from "gatsby";
import TaxContent, {
  TaxCountryContent,
} from "../components/organisms/taxContent/index";
import { PageTopic } from "../components/molecules/pageTopics";
import { CountryKey } from "../translations/countries";
import { createHtmlParserOptions } from "../utils/htmlParser";
import parse from "html-react-parser";
import {
  FeedbackContext,
  FeedbackProvider,
} from "../utils/contexts/feedbackContext";
import { rootPagePath, findCountryInPathname, findTopicInPathname } from "../utils/path";
import Topics from "../components/organisms/topics";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import AnonymousFeedback from "../components/organisms/anonymousFeedback";
import BaseWrapper from "../components/molecules/BaseWrapper";

export type DirectusTaxTopic = {
  id: string;
  presentation_order: number;
  status: string;
  translations: {
    languages_code: {
      code: string;
    };
    tax_topic_header: string;
  }[];
};

type DirectusTaxContent = {
  id: string;
  country: CountryKey;
  status: string;
  translations: {
    languages_code: {
      code: string;
    };
    html: string;
  }[];
  tax_topic: {
    id: string;
  };
};

type PageContext = {
  pageId: string;
  tax_topics: Array<DirectusTaxTopic>;
  tax_content: Array<DirectusTaxContent>;
  languageCode: string;
};

const StyledAnonymousFeedback = styled(AnonymousFeedback)`
  margin-top: ${rhythm(3)};
`;

const StyledTopics = styled(Topics)`
  margin-top: ${rhythm(3)};
`;

const TaxationPageTemplate: FC<PageProps> = ({
  location,
  navigate,
  pageContext,
}) => {
  const { search } = location;
  const { country: parsedCountry, topic: parsedTopic } = queryString.parse(
    search
  );

  const { pageId, languageCode, tax_topics, tax_content } = pageContext as PageContext;

  // Do not use path, there will be * at the end
  const pageUrl = `/${rootPagePath}${languageCode}/${pageId}`;

  const [selectedCountry, setSelectedCountry] = useState<CountryKey | undefined>(
    parsedCountry as CountryKey
  );
  
  const [selectedTopic, setSelectedTopic] = useState<string | undefined>(
    parsedTopic as string
  );

  const [{ header }, setHeader] = useState({
    header: getTranslation(languageCode as TranslationKey, "pageHeader"),
  });

  useEffect(() => {
    const lang: TranslationKey = languageCode as TranslationKey;
    const header = getTranslation(lang, "pageHeader");
    setHeader({ header });
  }, [setHeader, languageCode]);

  useEffect(() => {
    if(parsedCountry !== selectedCountry)
      setSelectedCountry(parsedCountry as CountryKey);
    if(parsedTopic !== selectedTopic)
      setSelectedTopic(parsedTopic as string);
  }, [navigate]);

  const mappedTaxTopics = tax_topics
    .filter((c) => isStatusAllowed(c.status))
    .map(({ id, presentation_order, translations }) => {
      const translation = translations.find(
        (t: { languages_code: { code: string } }) =>
          t.languages_code?.code == languageCode
      );

      if (!translation) {
        return null;
      }

      const taxTopic: PageTopic = {
        id,
        header: translation.tax_topic_header,
        presentationOrder: presentation_order,
      };
      return taxTopic;
    }) as PageTopic[];

  const parserOptions = createHtmlParserOptions(languageCode);

  const mappedTaxContents: TaxCountryContent = {};
  tax_content
    .filter((c) => isStatusAllowed(c.status))
    .forEach(({ id, country, translations, tax_topic }) => {
      const translation = translations.find(
        (t: { languages_code: { code: string } }) =>
          t.languages_code?.code == languageCode
      );

      if (!translation) {
        return null;
      }

      mappedTaxContents[tax_topic.id] = {
        ...mappedTaxContents[tax_topic.id],
        [country]: parse(translation.html, parserOptions),
      };
    });

  const handleCountrySelection = useCallback(
    (countryKey: CountryKey) => {
      setSelectedCountry(countryKey);
      let newPath = `${pageUrl}/`;
      if(countryKey || selectedTopic)
        newPath += `?${queryString.stringify({ country: countryKey, topic: selectedTopic })}`;
      
      if(location.pathname !== newPath)
        navigate(newPath);
    },
    [
      navigate,
      location,
      languageCode,
      selectedCountry
    ]
  );

  const handleTopicSelection = useCallback(
    (topic: string) => {
      setSelectedTopic(topic);
      let newPath = `${pageUrl}/`;
      if(topic || selectedCountry)
        newPath += `?${queryString.stringify({ country: selectedCountry, topic: topic })}`;
      
      if(location.pathname !== newPath)
        navigate(newPath);
    },
    [
      navigate,
      location,
      languageCode,
      selectedCountry
    ]
  );

  return (
    <BaseWrapper
      initialFeedbackState={{ showSendFeedback: true }}
      crumbLabel={header}
      SEOtitle={header}
      location={location}>
      <h1>{header}</h1>
      <TaxContent
        topics={mappedTaxTopics}
        contents={mappedTaxContents}
        selectedCountry={selectedCountry}
        onCountrySelected={handleCountrySelection}
        onTopicSelected={handleTopicSelection}
        selectedTopic={selectedTopic as string}
      />
      <FeedbackContext.Consumer>
        {({ showSendFeedback }) =>
          showSendFeedback && <StyledAnonymousFeedback />
        }
      </FeedbackContext.Consumer>
      <StyledTopics />
    </BaseWrapper>
  );
};

export default TaxationPageTemplate;
